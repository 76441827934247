import { Pipe, PipeTransform } from '@angular/core';
import { TransactionRequestType } from '../proto/goal-transaction-enums.pb';

@Pipe({
  name: 'transactionRequestTypeEnum',
})
export class TransactionRequestTypeEnumPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) return 'N/A';
    return TransactionRequestType[value];
  }
}
