import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[bp-callout]',
})
export class DBpCalloutDirective {
  constructor(private el: ElementRef) {
    //filled
    // this.el.nativeElement.classList.add('p-6', 'bg-card', 'rounded-2xl');
    //elevated

    this.el.nativeElement.classList.add(
      'p-4',
      'bg-gray-100',
      'rounded-md',
      'dark:text-gray-300',
      'dark:bg-gray-700',
    );
    //bordered
    // this.el.nativeElement.classList.add('p-6', 'bg-card', 'rounded-2xl', 'border-2');
  }
}
