import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[bp-link]',
})
export class LinkDirective {
  constructor(private el: ElementRef) {
    this.el.nativeElement.classList.add('text-base', 'text-primary', 'cursor-pointer');
  }
}
