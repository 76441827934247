import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[bp-text-values-card]',
})
export class TextValuesCardDirective {
  constructor(private el: ElementRef) {
    this.el.nativeElement.classList.add('text-base', 'text-secondary');
  }
}
