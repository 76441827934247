import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDashes',
})
export class RemoveDashes implements PipeTransform {
  transform(value: string | undefined, ...args: any[]): any {
    if (value) {
      return value.replace(/_/g, ' ');
    }
  }
}
