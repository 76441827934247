import { Pipe, PipeTransform } from '@angular/core';
import { ServiceTypeEnum } from '../proto/log-external.pb';

@Pipe({
  name: 'logExternalTypeEnum',
})
export class LogExternalTypeEnumPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) return '';
    return ServiceTypeEnum[value];
  }
}
