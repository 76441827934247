import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'incomeRange',
})
export class IncomeRangePipe implements PipeTransform {
  private mappings: Record<string, string> = {
    'menor-a-dollar40000': 'Menor a USD 40.000',
    'entre-dollar40000-y-dollar44999': 'Entre USD 40.000 y USD 44.999',
    'entre-dollar45000-y-dollar49999': 'Entre USD 45.000 y USD 49.999',
    'entre-dollar50000-y-dollar99999': 'Entre USD 50.000 y USD 99.999',
    'entre-dollar100000-y-dollar149000': 'Entre USD 100.000 y USD 149.999',
    'entre-dollar150000-y-dollar199999': 'Entre USD 150.000 y USD 199.999',
    'entre-dollar200000-y-dollar249999': 'Entre USD 200.000 y USD 249.999',
    'entre-dollar250000-y-dollar499999': 'Entre USD 250.000 y USD 499.999',
    'entre-dollar500000-y-dollar999999': 'Entre USD 500.000 y USD 999.999',
    'mas-de-dollar1000000': 'Más de USD 1.000.000',
    'entre-dollar5000-y-dollar19999': 'Entre USD 5.000 y USD 19.999',
    'entre-dollar20000-y-dollar49999': 'Entre USD 20.000 y USD 49.999',
    'entre-dollar50000-y-dollar74999': 'Entre USD 50.000 y USD 74.999',
    'entre-dollar75000-y-dollar99999': 'Entre USD 75.000 y USD 99.999',
    'entre-dollar1000000-y-dollar1999999': 'Entre USD 1.000.000 y USD 1.999.999',
    'entre-dollar2000000-y-dollar4999999': 'Entre USD 2.000.000 y USD 4.999.999',
    'entre-dollar5000000-y-dollar9999999': 'Entre USD 5.000.000 y USD 9.999.999',
    'entre-dollar10000000-y-dollar24999999': 'Entre USD 10.000.000 y USD 24.999.999',
    'entre-dollar25000000-y-dollar29999999': 'Entre USD 25.000.000 y USD 29.999.999',
    'entre-dollar30000000-y-dollar49999999': 'Entre USD 30.000.000 y USD 49.999.999',
    'mas-de-dollar50000000': 'Más de USD 50.000.000',
  };

  transform(value: string | undefined): string {
    if (!value) {
      return '---';
    }
    return this.mappings[value] || '---';
  }
}
