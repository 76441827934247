import { Pipe, PipeTransform } from '@angular/core';
import { BankNameEnum } from 'projects/bp-core/src/lib/models/portal/profile/kyk.form';

@Pipe({
  name: 'bankFormat'
})
export class BankFormat implements PipeTransform {
  transform(value: string | undefined, args?: any): any {
    if (value) {
      // @ts-ignore
      var bankName = BankNameEnum[value];
      if (bankName) return bankName;
    }
    return value;
  }
}
