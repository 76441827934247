import { Pipe, PipeTransform } from '@angular/core';
import { TemplateType } from '../proto/common-message.pb';

@Pipe({
  name: 'templateTypeEnum',
})
export class TemplateTypeEnumPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) {
      return '';
    }
    return TemplateType[value];
  }
}
