import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { InteractionEvent } from '../interaction-event.model';
import { TrackingService } from './tracking-service.interface';

export class GoogleTagTracker implements TrackingService {
  constructor(private gtmService: GoogleTagManagerService) {}

  trackEvent(event: InteractionEvent): void {
    this.gtmService
      .pushTag({
        event: event.eventName,
        ...event,
      })
      .then(() => {
        console.log('Google Tag Manager event sent:', event);
      })
      .catch(error => {
        console.error('Error sending Google Tag Manager event:', error);
      });
  }
}
