import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[bp-skeleton]',
})
export class SkeletonDirective implements OnChanges {
  @Input('bp-skeleton') loading: boolean;
  @Input() skeletonWidth: string;
  @Input() skeletonHeight: string;
  @Input() skeletonType: string = 'rectangle';
  @Input() skeletonExtraClass: string;
  @Input() skeletonCount: number = 1;

  private skeletonElement: HTMLElement;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if ('loading' in changes) {
      if (changes['loading'].currentValue) {
        this.createSkeleton();
        this.renderer.addClass(this.element.nativeElement, 'hidden');
      } else {
        this.removeSkeleton();
        this.renderer.removeClass(this.element.nativeElement, 'hidden');
      }
    }
  }

  private createSkeleton() {
    this.skeletonElement = this.renderer.createElement('div');
    for (let i = 0; i < this.skeletonCount; i++) {
      this.createSkeletonElement();
    }
  }
  private createSkeletonElement() {
    const singleSkeleton = this.renderer.createElement('div');
    this.renderer.addClass(singleSkeleton, 'w-' + this.skeletonWidth);

    if (this.skeletonType === 'circle') {
      this.renderer.addClass(singleSkeleton, 'h-' + this.skeletonWidth);
      this.renderer.addClass(singleSkeleton, 'flex-shrink-0');
    } else {
      this.renderer.addClass(singleSkeleton, 'h-' + this.skeletonHeight);
    }

    this.renderer.addClass(singleSkeleton, 'bg-gray-200');
    this.renderer.addClass(singleSkeleton, 'animate-pulse');
    this.renderer.addClass(singleSkeleton, 'rounded-full');
    this.renderer.addClass(singleSkeleton, 'dark:bg-gray-700');

    if (this.skeletonExtraClass) {
      const skeletonExtraClass = this.skeletonExtraClass.split(' ');
      skeletonExtraClass.forEach(element => {
        this.renderer.addClass(singleSkeleton, element);
      });
    }

    // añadir el singleSkeleton al skeletonElement
    this.renderer.appendChild(this.skeletonElement, singleSkeleton);

    this.renderer.appendChild(this.element.nativeElement.parentNode, this.skeletonElement);

    // this.renderer.insertBefore(
    //   this.element.nativeElement.parentNode,
    //   singleSkeleton,
    //   this.element.nativeElement,
    // );
  }
  private removeSkeleton() {
    if (this.skeletonElement) {
      this.renderer.removeChild(this.element.nativeElement.parentNode, this.skeletonElement);
    }
  }
}
