import { Pipe, PipeTransform } from '@angular/core';
import { FinancialEntityEmailType } from '../proto/common-message.pb';

@Pipe({
  name: 'financialEntityEmailTypeEnum',
})
export class FinancialEntityEmailTypeEnumPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) return '';
    return FinancialEntityEmailType[value];
  }
}
