export interface IKYCForm {
  laborOld?: string;
  stakeCompany?: string;
  hasStakeCompany?: boolean;
  regionId?: number;
  profession?: boolean;
  whatProfession?: string;
  gender?: string;
  civilStatus?: CivilStatusEnum;
  activity?: ActivityEnum;
  communeId?: number;
  address?: string;
  cityOfBirth?: string;
  countryOfBirth?: string;
  nationality?: string;
  usPerson?: boolean;
  isPEP?: boolean;
  whoIsPEP?: string;
  pepMePosition?: string;
  pepMeInstitution?: string;
  pepName?: string;
  pepPosition?: string;
  pepInstitution?: string;
  pepRelationship?: string;
  pepNameSecond?: string;
  pepPositionSecond?: string;
  pepInstitutionSecond?: string;
  pepRelationshipSecond?: string;
  pepFromDate?: Date;
  pepToDate?: Date;
  hasOtherNationality?: boolean;
  otherNationality?: string;
  otherCountryTaxDate?: Date;
  passportNumber?: string;
  hasAnotherTaxResidence?: boolean;
  otherCountryTaxDomicile?: string;
  taxpayerNumber?: string;
  averageIncome?: string;
  equityFinancialSystem?: string;
  nonfinancialAssets?: string;
  heritageOrigin?: SourceFundEnum;
  workCompanyName?: string;
  workRutCompany?: string;
  workJob?: string;
  workAddress?: string;
  workCommuneId?: number;
  workRegionId?: number;
  workProfession?: string;
  spouseNames?: string;
  spouseRUT?: string;
  spouseBirthDate?: Date | string;
  spouseActivity?: string;
  spouseNationality?: string;
  spouseAddress?: string;
  spousePhone?: string;
  spousenEmail?: string;
  spousenCommuneId?: number;
  spousenRegionId?: number;
  spouceWorkCompanyName?: string;
  spouceWorkRutCompany?: string;
  spouceWorkJob?: string;
  spouceWorkAddress?: string;
  spouceWorkProfession?: string;
  maritalRegime?: string;
  bankTranfer?: string;
  accountTypeTranfer?: string;
  accountNumberTranfer?: string;
  sourceFunds?: string;
  fatcaReportAuthorization?: boolean;
  fatcaUsTaxpayerIdentificationNumber?: string;
}

export enum InternalTransactionTypeEnum {
  changePortfolioSale = 'Cambio de portafolio',
  changePortfolioBuy = 'Cambio de portafolio',
  transferGoalSale = 'Transferencia entre metas',
  transferGoalBuy = 'Transferencia entre metas',
  sale = 'Retiro',
  buy = 'Depósito',
}

export const InternalTransactionTypeRecord: Record<number, string> = {
  3: 'Cambio de portafolio',
  2: 'Cambio de portafolio',
  5: 'Transferencia entre metas',
  4: 'Transferencia entre metas',
  1: 'Retiro',
  0: 'Depósito',
};

export enum EntityTypeEnum {
  ANONIMA = 'Anómina',
  COLECTIVA = 'Colectiva',
  EN_COMANDITA = 'En Comandita',
  LIMITADA = 'Limitada',
  EIRL = 'EIRL',
  SPA = 'SPA',
  OTRO = 'Otro',
}

export enum BankNameEnum {
  BANCO_ESTADO = 'BANCO ESTADO',
  COOPEUCH = 'COOPEUCH',
  BANCO_BBVA = 'BANCO BBVA',
  BANCO_CONSORCIO = 'BANCO CONSORCIO',
  BANCO_RIPLEY = 'BANCO RIPLEY',
  BANCO_FALABELLA = 'BANCO FALABELLA',
  BANCO_SECURITY = 'BANCO SECURITY',
  THE_BANK_OF_TOKYO_MITSUBISHI_UFJ = 'THE BANK OF TOKYO-MITSUBISHI UFJ',
  BANCO_ITAU = 'BANCO ITAU',
  BANCO_SANTANDER = 'BANCO SANTANDER',
  HSBC_BANK_CHILE = 'HSBC BANK CHILE',
  BICE = 'BICE',
  CORP_BANCA = 'CORPBANCA',
  BANCO_DE_CREDITO_E_INVERSIONES = 'BANCO DE CREDITO E INVERSIONES',
  SCOTIABANK_DESARROLLO = 'SCOTIABANK CHILE',
  BANCO_INTERNACIONAL = 'BANCO INTERNACIONAL',
  BANCO_DE_CHILE = 'BANCO DE CHILE',
}
export enum AccountTypeBankEnum {
  CUENTA_CORRIENTE = 'Cuenta Corriente',
  CUENTA_VISTA = 'Cuenta Vista',
  CUENTA_AHORRO = 'Cuenta Ahorro',
}
export enum CivilStatusEnum {
  single = 'Soltera/o',
  divorced = 'Divorciada/o',
  married = 'Casada/o',
  widower = 'Viuda/o',
  civilUnion = 'Conviviente civil',
}
export enum MaritalRegimesEnum {
  profitSharing = 'Participación de ganancias',
  separationOfProperty = 'Separación de bienes',
  withoutRegime = 'Sin Régimen',
  conjugalSociety = 'Sociedad conyugal',
  intermediation = 'Intermediación',
  notInformed = 'No informado(a)',
}
export enum ActivityEnum {
  employee = 'Empleado',
  independent = 'Independiente',
  retired = 'Jubilado',
  other = 'Sin empleo',
}
export enum GenderEnum {
  female = 'Femenino',
  male = 'Masculino',
}

export enum SourceFundEnum {
  salaries = 'Sueldo',
  investments = 'Inversiones',
  properties = 'Venta de activos',
  heritage = 'Herencia',
  savings = 'Ahorros',
  otherSource = 'Otros',
}

export enum MaritalRegime {
  wealthSeparation = 'Separacíon de bienes',
  maritalSociety = 'Sociedad Conyugal',
  earningParticipation = 'Participacíon en las ganancias',
  intermediation = 'Intermediacíon',
  noRegime = 'Sin régimen',
}

export enum FeedbackTypeEnum {
  general = 'general',
  rescue = 'rescue',
  rescueToGoal = 'transferencia-entre-metas',
  changePortfolio = 'cambio-portafolio',
}
