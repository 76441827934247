import { ApmService } from '@elastic/apm-rum-angular';
import { InteractionEvent } from '../interaction-event.model';
import { TrackingService } from './tracking-service.interface';

export class ApmTracker implements TrackingService {
  constructor(private apmService: ApmService) {}

  trackEvent(event: InteractionEvent): void {
    const transaction = this.apmService.apm.startTransaction(
      event.eventName,
      'user-interaction-events',
    );
    transaction?.addLabels({ ...event });
    transaction?.end();
  }
}
