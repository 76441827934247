import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { EventNameType } from '../models/interaction-event.model';
import { AppInteractionService } from '../services/app-interaction.service';

@Directive({
  selector: '[appTrackEvent]',
})
export class TrackEventDirective implements OnInit, OnDestroy {
  @Input() trackEventName: EventNameType;
  @Input() trackEventDetail: string;
  @Input() trackEventType: string = 'click';

  private eventUnsubscribeFn: Function | null = null;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private interactionService: AppInteractionService,
  ) {}

  ngOnInit() {
    // Asegúrate de que el tipo de evento es válido, por ejemplo 'click', 'mouseenter', etc.
    this.eventUnsubscribeFn = this.renderer.listen(
      this.el.nativeElement,
      this.trackEventType,
      () => {
        this.interactionService.emit({
          eventName: this.trackEventName,
          eventDetail: this.trackEventDetail,
        });
      },
    );
  }

  ngOnDestroy() {
    // Eliminar la escucha del evento cuando el elemento sea destruido
    if (this.eventUnsubscribeFn) this.eventUnsubscribeFn();
  }
}
