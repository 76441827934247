import { Pipe, PipeTransform } from '@angular/core';
import { GoalTransactionType } from '../proto/goal-transaction-type-enums.pb';

@Pipe({
  name: 'goalTransactionTypeEnum',
})
export class GoalTransactionTypeEnumPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) return '';
    return GoalTransactionType[value];
  }
}
