import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[bp-card]',
})
export class DBpCardDirective implements OnInit {
  @Input('bpCardPadding') hasPadding: boolean = true;

  constructor(private el: ElementRef) {
    //filled
    // this.el.nativeElement.classList.add('p-6', 'bg-card', 'rounded-2xl');
    //elevated
    //this.el.nativeElement.classList.add('p-6', 'bg-card', 'rounded-2xl', 'shadow');
    //bordered
    // this.el.nativeElement.classList.add('p-6', 'bg-card', 'rounded-2xl', 'border-2');
  }
  ngOnInit() {
    if (this.hasPadding) {
      this.el.nativeElement.classList.add(
        'p-6',
        'bg-card',
        'rounded-2xl',
        'shadow',
        'overflow-hidden',
      );
    } else {
      this.el.nativeElement.classList.add('bg-card', 'rounded-2xl', 'shadow', 'overflow-hidden');
    }
  }
}
