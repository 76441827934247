import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@ngx-grpc/well-known-types';

@Pipe({
  name: 'bsonValue',
  standalone: true,
})
export class BsonValuePipe implements PipeTransform {
  transform(value: any): string {
    if (value === null || value === undefined) {
      return 'null';
    }

    if (typeof value === 'object' && 'value' in value) {
      return this.transform(value.value);
    }

    if (value instanceof Date) {
      return value.toISOString();
    }

    if (value instanceof Timestamp) {
      const date = new Date(Number(value.seconds) * 1000 + Number(value.nanos) / 1000000);
      return date.toISOString();
    }

    if (typeof value === 'object') {
      try {
        // Intenta parsear el objeto si es una cadena JSON
        const parsed = JSON.parse(JSON.stringify(value));
        return JSON.stringify(parsed, null, 2);
      } catch {
        // Si no es JSON válido, simplemente stringifica el objeto
        return JSON.stringify(value, null, 2);
      }
    }

    return String(value);
  }
}
