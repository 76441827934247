import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyModel } from '../proto/common-message.pb';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(
    value?: number,
    currencyModel?: CurrencyModel,
    decimalOverride?: number,
  ): string | null {
    if (
      !currencyModel ||
      !currencyModel.currencyCode ||
      !currencyModel.digitsInfo ||
      value === undefined
    )
      return null;

    let modifiedDigitsInfo = currencyModel.digitsInfo;

    if (decimalOverride !== undefined) {
      const [intPart, decimalPart] = currencyModel.digitsInfo.split('.');
      if (decimalPart) {
        modifiedDigitsInfo = `${intPart}.${decimalOverride}-${decimalOverride}`;
      }
    }

    // Si el valor es un numero entero, establecemos los decimales a 0
    if (Number.isInteger(value)) {
      const [intPart] = modifiedDigitsInfo.split('.');
      modifiedDigitsInfo = `${intPart}.0-0`;
    }

    const displayCode = currencyModel.display || currencyModel.currencyCode;

    // Aplicar el Pipe de currency de Angular con el modelo modificado
    return this.currencyPipe.transform(value, displayCode, 'symbol', modifiedDigitsInfo);
  }
}
