/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
export enum ServiceTypeEnum {
  amh_informar_consumo = 0,
  amh_confirm_deposit = 1,
  fintoc_create_payment_intents = 2
}
/**
 * Message implementation for log_external.ListForEntityRequest
 */
export class ListForEntityRequest implements GrpcMessage {
  static id = 'log_external.ListForEntityRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListForEntityRequest();
    ListForEntityRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListForEntityRequest) {
    _instance.entity = _instance.entity || '';
    _instance.entityId = _instance.entityId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListForEntityRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.entity = _reader.readString();
          break;
        case 2:
          _instance.entityId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ListForEntityRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListForEntityRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.entity) {
      _writer.writeString(1, _instance.entity);
    }
    if (_instance.entityId) {
      _writer.writeString(2, _instance.entityId);
    }
  }

  private _entity: string;
  private _entityId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListForEntityRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListForEntityRequest.AsObject>) {
    _value = _value || {};
    this.entity = _value.entity;
    this.entityId = _value.entityId;
    ListForEntityRequest.refineValues(this);
  }
  get entity(): string {
    return this._entity;
  }
  set entity(value: string) {
    this._entity = value;
  }
  get entityId(): string {
    return this._entityId;
  }
  set entityId(value: string) {
    this._entityId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListForEntityRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListForEntityRequest.AsObject {
    return {
      entity: this.entity,
      entityId: this.entityId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListForEntityRequest.AsProtobufJSON {
    return {
      entity: this.entity,
      entityId: this.entityId
    };
  }
}
export module ListForEntityRequest {
  /**
   * Standard JavaScript object representation for ListForEntityRequest
   */
  export interface AsObject {
    entity: string;
    entityId: string;
  }

  /**
   * Protobuf JSON representation for ListForEntityRequest
   */
  export interface AsProtobufJSON {
    entity: string;
    entityId: string;
  }
}

/**
 * Message implementation for log_external.ListForEntityResponse
 */
export class ListForEntityResponse implements GrpcMessage {
  static id = 'log_external.ListForEntityResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListForEntityResponse();
    ListForEntityResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListForEntityResponse) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListForEntityResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new LogExternalModel();
          _reader.readMessage(
            messageInitializer1,
            LogExternalModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    ListForEntityResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListForEntityResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        LogExternalModel.serializeBinaryToWriter
      );
    }
  }

  private _values?: LogExternalModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListForEntityResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListForEntityResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new LogExternalModel(m));
    ListForEntityResponse.refineValues(this);
  }
  get values(): LogExternalModel[] | undefined {
    return this._values;
  }
  set values(value: LogExternalModel[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListForEntityResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListForEntityResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListForEntityResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module ListForEntityResponse {
  /**
   * Standard JavaScript object representation for ListForEntityResponse
   */
  export interface AsObject {
    values?: LogExternalModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for ListForEntityResponse
   */
  export interface AsProtobufJSON {
    values: LogExternalModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for log_external.ListLogExternalResponse
 */
export class ListLogExternalResponse implements GrpcMessage {
  static id = 'log_external.ListLogExternalResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListLogExternalResponse();
    ListLogExternalResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListLogExternalResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListLogExternalResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new LogExternalModel();
          _reader.readMessage(
            messageInitializer1,
            LogExternalModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListLogExternalResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListLogExternalResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        LogExternalModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: LogExternalModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListLogExternalResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListLogExternalResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(m => new LogExternalModel(m));
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListLogExternalResponse.refineValues(this);
  }
  get values(): LogExternalModel[] | undefined {
    return this._values;
  }
  set values(value: LogExternalModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListLogExternalResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListLogExternalResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListLogExternalResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListLogExternalResponse {
  /**
   * Standard JavaScript object representation for ListLogExternalResponse
   */
  export interface AsObject {
    values?: LogExternalModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListLogExternalResponse
   */
  export interface AsProtobufJSON {
    values: LogExternalModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for log_external.LogExternalModel
 */
export class LogExternalModel implements GrpcMessage {
  static id = 'log_external.LogExternalModel';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LogExternalModel();
    LogExternalModel.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LogExternalModel) {
    _instance.objectId = _instance.objectId || '';
    _instance.type = _instance.type || 0;
    _instance.raw = _instance.raw || '';
    _instance.entity = _instance.entity || '';
    _instance.entityId = _instance.entityId || '';
    _instance.createdAt = _instance.createdAt || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LogExternalModel,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.objectId = _reader.readString();
          break;
        case 2:
          _instance.type = _reader.readEnum();
          break;
        case 3:
          _instance.raw = _reader.readString();
          break;
        case 4:
          _instance.entity = _reader.readString();
          break;
        case 5:
          _instance.entityId = _reader.readString();
          break;
        case 6:
          _instance.createdAt = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.createdAt,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    LogExternalModel.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LogExternalModel,
    _writer: BinaryWriter
  ) {
    if (_instance.objectId) {
      _writer.writeString(1, _instance.objectId);
    }
    if (_instance.type) {
      _writer.writeEnum(2, _instance.type);
    }
    if (_instance.raw) {
      _writer.writeString(3, _instance.raw);
    }
    if (_instance.entity) {
      _writer.writeString(4, _instance.entity);
    }
    if (_instance.entityId) {
      _writer.writeString(5, _instance.entityId);
    }
    if (_instance.createdAt) {
      _writer.writeMessage(
        6,
        _instance.createdAt as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _objectId: string;
  private _type: ServiceTypeEnum;
  private _raw: string;
  private _entity: string;
  private _entityId: string;
  private _createdAt?: googleProtobuf001.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LogExternalModel to deeply clone from
   */
  constructor(_value?: RecursivePartial<LogExternalModel.AsObject>) {
    _value = _value || {};
    this.objectId = _value.objectId;
    this.type = _value.type;
    this.raw = _value.raw;
    this.entity = _value.entity;
    this.entityId = _value.entityId;
    this.createdAt = _value.createdAt
      ? new googleProtobuf001.Timestamp(_value.createdAt)
      : undefined;
    LogExternalModel.refineValues(this);
  }
  get objectId(): string {
    return this._objectId;
  }
  set objectId(value: string) {
    this._objectId = value;
  }
  get type(): ServiceTypeEnum {
    return this._type;
  }
  set type(value: ServiceTypeEnum) {
    this._type = value;
  }
  get raw(): string {
    return this._raw;
  }
  set raw(value: string) {
    this._raw = value;
  }
  get entity(): string {
    return this._entity;
  }
  set entity(value: string) {
    this._entity = value;
  }
  get entityId(): string {
    return this._entityId;
  }
  set entityId(value: string) {
    this._entityId = value;
  }
  get createdAt(): googleProtobuf001.Timestamp | undefined {
    return this._createdAt;
  }
  set createdAt(value: googleProtobuf001.Timestamp | undefined) {
    this._createdAt = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LogExternalModel.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LogExternalModel.AsObject {
    return {
      objectId: this.objectId,
      type: this.type,
      raw: this.raw,
      entity: this.entity,
      entityId: this.entityId,
      createdAt: this.createdAt ? this.createdAt.toObject() : undefined
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LogExternalModel.AsProtobufJSON {
    return {
      objectId: this.objectId,
      type:
        ServiceTypeEnum[
          this.type === null || this.type === undefined ? 0 : this.type
        ],
      raw: this.raw,
      entity: this.entity,
      entityId: this.entityId,
      createdAt: this.createdAt ? this.createdAt.toProtobufJSON(options) : null
    };
  }
}
export module LogExternalModel {
  /**
   * Standard JavaScript object representation for LogExternalModel
   */
  export interface AsObject {
    objectId: string;
    type: ServiceTypeEnum;
    raw: string;
    entity: string;
    entityId: string;
    createdAt?: googleProtobuf001.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for LogExternalModel
   */
  export interface AsProtobufJSON {
    objectId: string;
    type: string;
    raw: string;
    entity: string;
    entityId: string;
    createdAt: googleProtobuf001.Timestamp.AsProtobufJSON | null;
  }
}
