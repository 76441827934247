import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[bp-tag]',
})
export class BpTagDirective implements OnInit, OnChanges {
  @Input('bp-tag') color: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.classList.add(
      'px-2.5',
      'text-center',
      'py-0.5',
      'text-sm',
      'rounded',
      'flex-shrink-0',
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      const prevColor = changes.color.previousValue || 'primary';
      // Remueve las clases del color anterior
      this.el.nativeElement.classList.remove(`text-${prevColor}-500`);
      this.el.nativeElement.classList.remove(`bg-${prevColor}-100`);

      // Añade las clases del color nuevo
      const newColor = this.color || 'primary';
      this.el.nativeElement.classList.add(`text-${newColor}-500`);
      this.el.nativeElement.classList.add(`bg-${newColor}-100`);
    }
  }
}
