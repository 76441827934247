import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyJson',
})
export class PrettyJsonPipe implements PipeTransform {
  transform(value: string): string {
    try {
      const json = JSON.parse(value);
      return JSON.stringify(json, null, 2);
    } catch (e) {
      return value;
    }
  }
}
