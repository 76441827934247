import { Pipe, PipeTransform } from '@angular/core';
import { TransactionRequestType } from '../proto/goal-transaction-enums.pb';

@Pipe({
  name: 'transactionRequestTypeFormat',
})
export class TransactionRequestTypeFormat implements PipeTransform {
  transactionRequestTypeString: Record<TransactionRequestType, string> = {
    [TransactionRequestType.Buy]: 'Depósito',
    [TransactionRequestType.Sale]: 'Retiro',
    [TransactionRequestType.ChangePortfolio]: 'Cambio de portafolio',
    [TransactionRequestType.TransferGoal]: 'Transferencia entre metas',
    [TransactionRequestType.Comission]: 'Comisión',
    [TransactionRequestType.Dividend]: 'Dividendos',
  };

  transform(value: TransactionRequestType | undefined, args?: any): any {
    if (value !== undefined) {
      var transactionTypeFormatName = TransactionRequestType[value];

      if (transactionTypeFormatName) {
        return this.transactionRequestTypeString[value];
      }
      return value;
    }
    return value;
  }
}
