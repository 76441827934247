import { Pipe, PipeTransform } from '@angular/core';
import { InternalNotificationEventConfigurationType } from '../proto/common-message.pb';

@Pipe({
  name: 'internalNotificationneventConfigurationTypeEnum',
})
export class InternalNotificationneventConfigurationTypeEnumPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) {
      return '';
    }
    return InternalNotificationEventConfigurationType[value];
  }
}
