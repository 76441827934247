import { Pipe, PipeTransform } from '@angular/core';
import { TransactionRequestState } from '../proto/goal-transaction-enums.pb';

@Pipe({
  name: 'transactionRequestStateEnum',
})
export class TransactionRequestStateEnumPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) return 'N/A';
    return TransactionRequestState[value];
  }
}
