import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[bp-badge]',
})
export class BpBadgeDirective {
  @Input('bp-badge') color: string;

  constructor(private el: ElementRef) {
    this.el.nativeElement.classList.add(
      'px-2.5',
      'py-0',
      'text-sm',
      'text-white',
      'rounded-full',
      `bg-${this.color ?? 'primary'}`,
      'flex-shrink-0',
    );
  }
}
