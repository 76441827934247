import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[bp-title-card]',
})
export class BpTitleCardDirective {
  @Input() fontNormal: boolean | undefined;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.el.nativeElement.classList.add(
      'text-lg',
      this.fontNormal ? 'font-normal' : 'font-semibold',
      'leading-6',
      'tracking-tight',
    );
  }
}
