import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-bp-core',
  template: `
    <p>
      bp-core works!
    </p>
  `,
  styles: [],
})
export class BpCoreComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
