import { Pipe, PipeTransform } from '@angular/core';
import { OriginType } from '../proto/goal-transaction-origin-type-enum.pb';

@Pipe({
  name: 'originTypeEnum',
})
export class OriginTypeEnumPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value === undefined) return '';
    return OriginType[value];
  }
}
