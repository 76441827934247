import { Pipe, PipeTransform } from '@angular/core';
import { GoalTransactionType } from '../proto/goal-transaction-type-enums.pb';

@Pipe({
  name: 'transactionTypeFormat',
})
export class TransactionTypeFormat implements PipeTransform {
  transform(value: number | undefined, args?: any): any {
    if (value !== undefined) {
      var transactionTypeFormatName = GoalTransactionType[value];

      if (transactionTypeFormatName) {
        if (transactionTypeFormatName.includes('DIVIDEND')) return 'Dividendos';
        if (transactionTypeFormatName.includes('CHANGE_PORTFOLIO')) return 'Cambio de portafolio';
        if (transactionTypeFormatName.includes('TRANSFER')) return 'Transferencia entre meta';
        if (transactionTypeFormatName.includes('BUY')) return 'Inversión';
        if (transactionTypeFormatName.includes('DEPOSIT')) return 'Depósito';
        if (transactionTypeFormatName.includes('INSTRUMENT_SALE')) return 'Venta';
        return 'Retiro';
      }
      return value;
    }
    return value;
  }
}
