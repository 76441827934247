import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[bp-header-title]',
})
export class BpHeaderTitleDirective {
  constructor(private el: ElementRef) {
    this.el.nativeElement.classList.add(
      'text-3xl',
      'font-semibold',
      'leading-6',
      'tracking-tight',
      'md:text-4xl',
      'md:leading-snug',
    );
  }
}
