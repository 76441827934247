import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[bp-goal-detail-card]',
})
export class DBpGoalDetailCardDirective implements OnInit {
  @Input('bpCardPadding') hasPadding: boolean = true;

  constructor(private el: ElementRef) {
    //filled
    // this.el.nativeElement.classList.add('p-6', 'bg-card', 'rounded-2xl');
    //elevated
    //this.el.nativeElement.classList.add('p-6', 'bg-card', 'rounded-2xl', 'shadow');
    //bordered
    // this.el.nativeElement.classList.add('p-6', 'bg-card', 'rounded-2xl', 'border-2');
  }

  ngOnInit() {
    this.el.nativeElement.classList.add(
      `p-${this.hasPadding ? '6' : '0'}`,
      'bg-card',
      'sm:rounded-2xl',
      'sm:shadow',
    );
  }
}
