import { Angulartics2 } from 'angulartics2';
import { InteractionEvent } from '../interaction-event.model';
import { TrackingService } from './tracking-service.interface';

export class Angulartics2Tracker implements TrackingService {
  constructor(private angulartics2: Angulartics2) {}

  trackEvent(event: InteractionEvent): void {
    this.angulartics2.eventTrack.next({
      action: event.eventName,
      properties: { ...event },
    });
  }
}
