import { Pipe, PipeTransform } from '@angular/core';
import { AccountTypeBankEnum } from 'projects/bp-core/src/lib/models/portal/profile/kyk.form';

@Pipe({
  name: 'accountTypeFormat'
})
export class AccountTypeFormat implements PipeTransform {
  transform(value: string | undefined, args?: any): any {
    if (value) {
      // @ts-ignore
      var accountTypeName = AccountTypeBankEnum[value];
      if (accountTypeName) return accountTypeName;
    }
    return value;
  }
}
