import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[bp-transition]',
})
export class BpTransitionDirective {
  constructor(private el: ElementRef) {
    this.el.nativeElement.classList.add(
      'transition',
      'transition-all',
      'duration-300',
      'ease-in-out',
    );
  }
}
